import React, { useState, useEffect } from 'react';
import { supabase, fetchVendorEventTypes } from '../../supabaseClient';
import { FaCalendarAlt, FaFilter, FaPrint, FaDownload } from 'react-icons/fa';
import { 
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
  BarChart, Bar
} from 'recharts';

const ReportsTab = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    filterType: 'preset',
    dateRange: 'month',
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(),
    eventType: ''
  });
  const [eventTypes, setEventTypes] = useState([]);
  const [reportData, setReportData] = useState(null);

  // Fetch event types on component mount
  useEffect(() => {
    const loadEventTypes = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) throw new Error('No user found');

        const { data: vendorData } = await supabase
          .from('vendors')
          .select('id')
          .eq('user_id', user.id)
          .single();

        const { data: types } = await fetchVendorEventTypes(vendorData.id);
        setEventTypes(types || []);
      } catch (error) {
        console.error('Error loading event types:', error);
        setError(error.message);
      }
    };

    loadEventTypes();
  }, []);

  // Fetch report data when filters change
  useEffect(() => {
    const fetchReportData = async () => {
      setLoading(true);
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) throw new Error('No user found');

        const { data: vendorData } = await supabase
          .from('vendors')
          .select('id')
          .eq('user_id', user.id)
          .single();

        let query = supabase
          .from('tickets_sold')
          .select(`
            *,
            events:event_id (
              title,
              event_type,
              gender_type,
              start_date,
              location
            )
          `)
          .eq('vendor_id', vendorData.id)
          .gte('purchase_date', filters.startDate.toISOString())
          .lte('purchase_date', filters.endDate.toISOString());

        if (filters.eventType) {
          query = query.eq('events.event_type', filters.eventType);
        }

        const { data, error: queryError } = await query;
        if (queryError) throw queryError;

        const processedData = processReportData(data || []);
        setReportData(processedData);
      } catch (error) {
        console.error('Error fetching report data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchReportData();
  }, [filters]);

  const processReportData = (data) => {
    const dailyData = data.reduce((acc, ticket) => {
      const date = new Date(ticket.purchase_date).toLocaleDateString();
      if (!acc[date]) {
        acc[date] = {
          revenue: 0,
          tickets: 0,
          events: new Set()
        };
      }
      acc[date].revenue += ticket.price_paid || 0;
      acc[date].tickets += 1;
      acc[date].events.add(ticket.events?.title);
      return acc;
    }, {});

    // Calculate recurring buyers
    const buyerCounts = data.reduce((acc, ticket) => {
      acc[ticket.email] = (acc[ticket.email] || 0) + 1;
      return acc;
    }, {});
    
    const buyers = Object.entries(buyerCounts);
    const totalBuyers = buyers.length;
    const recurringBuyers = buyers.filter(([_, count]) => count > 1).length;

    // Calculate best performing event type
    const eventTypePerformance = data.reduce((acc, ticket) => {
      const eventType = ticket.events?.event_type || 'Unknown';
      if (!acc[eventType]) {
        acc[eventType] = {
          revenue: 0,
          tickets: 0
        };
      }
      acc[eventType].revenue += ticket.price_paid || 0;
      acc[eventType].tickets += 1;
      return acc;
    }, {});

    const bestEventType = Object.entries(eventTypePerformance)
      .map(([type, stats]) => ({
        type,
        ...stats
      }))
      .sort((a, b) => b.revenue - a.revenue)[0] || {
        type: 'No events',
        revenue: 0,
        tickets: 0
      };

    // Calculate peak sales time
    const hourCounts = data.reduce((acc, ticket) => {
      const purchaseHour = new Date(ticket.purchase_date).getHours();
      acc[purchaseHour] = (acc[purchaseHour] || 0) + 1;
      return acc;
    }, {});

    let peakHour = 0;
    let maxSales = 0;
    Object.entries(hourCounts).forEach(([hour, count]) => {
      if (count > maxSales) {
        peakHour = parseInt(hour);
        maxSales = count;
      }
    });

    const formatPeakTime = (hour) => {
      if (maxSales === 0) return 'No sales';
      const period = hour >= 12 ? 'PM' : 'AM';
      const displayHour = hour % 12 || 12;
      return `${displayHour}-${displayHour + 1} ${period}`;
    };

    return {
      dailySales: Object.entries(dailyData).map(([date, stats]) => ({
        date,
        revenue: stats.revenue,
        tickets: stats.tickets,
        uniqueEvents: stats.events.size
      })),
      totalRevenue: data.reduce((sum, ticket) => sum + (ticket.price_paid || 0), 0),
      totalTickets: data.length,
      recurringBuyers,
      totalBuyers,
      bestEventType,
      peakSalesTime: formatPeakTime(peakHour),
      returnRate: ((recurringBuyers / totalBuyers) * 100 || 0).toFixed(1)
    };
  };

  const handleDateRangeChange = (range, filterType = 'preset') => {
    const now = new Date();
    let startDate = new Date();
    let endDate = now;

    if (filterType === 'preset') {
      switch (range) {
        case 'month':
          startDate = new Date(now.getFullYear(), now.getMonth(), 1);
          break;
        case '3months':
          startDate = new Date(now.getFullYear(), now.getMonth() - 3, 1);
          break;
        case '6months':
          startDate = new Date(now.getFullYear(), now.getMonth() - 6, 1);
          break;
        case '9months':
          startDate = new Date(now.getFullYear(), now.getMonth() - 9, 1);
          break;
        case 'year':
          startDate = new Date(now.getFullYear() - 1, now.getMonth(), 1);
          break;
        default:
          return;
      }
    } else if (filterType === 'monthly') {
      // For monthly filter, range will be in format "YYYY-MM"
      const [year, month] = range.split('-').map(num => parseInt(num));
      startDate = new Date(year, month - 1, 1); // month is 0-based
      endDate = new Date(year, month, 0); // last day of selected month
    }

    setFilters(prev => ({
      ...prev,
      dateRange: range,
      startDate,
      endDate
    }));
  };

  const handlePrint = () => {
    const printContent = document.createElement('div');
    printContent.innerHTML = `
      <div style="padding: 20px;">
        <h1 style="text-align: center; margin-bottom: 20px;">Sales Report</h1>
        <h2>Summary (${filters.startDate.toLocaleDateString()} - ${filters.endDate.toLocaleDateString()})</h2>
        <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
          <tr>
            <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Metric</th>
            <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Value</th>
          </tr>
          <tr>
            <td style="border: 1px solid #ddd; padding: 8px;">Total Revenue</td>
            <td style="border: 1px solid #ddd; padding: 8px;">$${reportData.totalRevenue.toFixed(2)}</td>
          </tr>
          <tr>
            <td style="border: 1px solid #ddd; padding: 8px;">Total Tickets</td>
            <td style="border: 1px solid #ddd; padding: 8px;">${reportData.totalTickets}</td>
          </tr>
          <tr>
            <td style="border: 1px solid #ddd; padding: 8px;">Total Buyers</td>
            <td style="border: 1px solid #ddd; padding: 8px;">${reportData.totalBuyers}</td>
          </tr>
          <tr>
            <td style="border: 1px solid #ddd; padding: 8px;">Recurring Buyers</td>
            <td style="border: 1px solid #ddd; padding: 8px;">${reportData.recurringBuyers}</td>
          </tr>
          <tr>
            <td style="border: 1px solid #ddd; padding: 8px;">Return Rate</td>
            <td style="border: 1px solid #ddd; padding: 8px;">${reportData.returnRate}%</td>
          </tr>
          <tr>
            <td style="border: 1px solid #ddd; padding: 8px;">Best Event Type</td>
            <td style="border: 1px solid #ddd; padding: 8px;">${reportData.bestEventType.type} ($${reportData.bestEventType.revenue.toFixed(2)})</td>
          </tr>
          <tr>
            <td style="border: 1px solid #ddd; padding: 8px;">Peak Sales Time</td>
            <td style="border: 1px solid #ddd; padding: 8px;">${reportData.peakSalesTime}</td>
          </tr>
        </table>

        <h2>Daily Sales Breakdown</h2>
        <table style="width: 100%; border-collapse: collapse;">
          <tr>
            <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Date</th>
            <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Revenue</th>
            <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Tickets</th>
            <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Events</th>
          </tr>
          ${reportData.dailySales.map(day => `
            <tr>
              <td style="border: 1px solid #ddd; padding: 8px;">${day.date}</td>
              <td style="border: 1px solid #ddd; padding: 8px;">$${day.revenue.toFixed(2)}</td>
              <td style="border: 1px solid #ddd; padding: 8px;">${day.tickets}</td>
              <td style="border: 1px solid #ddd; padding: 8px;">${day.uniqueEvents}</td>
            </tr>
          `).join('')}
        </table>
      </div>
    `;

    const printWindow = window.open('', '_blank');
    printWindow.document.write(printContent.innerHTML);
    printWindow.document.close();
    printWindow.print();
  };

  const handleExport = () => {
    if (!reportData) return;

    const csvContent = [
      ['Date', 'Revenue', 'Tickets Sold', 'Unique Events'],
      ...reportData.dailySales.map(day => [
        day.date,
        day.revenue.toFixed(2),
        day.tickets,
        day.uniqueEvents
      ])
    ].map(row => row.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `sales-report-${filters.startDate.toISOString().split('T')[0]}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="p-6 space-y-8">
      {/* Filters Section */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {/* Filter Type Selector */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Filter Type</label>
            <select
              value={filters.filterType}
              onChange={(e) => setFilters(prev => ({ ...prev, filterType: e.target.value }))}
              className="w-full border rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 outline-none"
            >
              <option value="preset">Preset Ranges</option>
              <option value="monthly">Monthly Filter</option>
            </select>
          </div>

          {/* Date Range Filter - Show only if preset is selected */}
          {filters.filterType === 'preset' && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Preset Ranges */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Preset Ranges</label>
                <select
                  value={filters.dateRange}
                  onChange={(e) => handleDateRangeChange(e.target.value, 'preset')}
                  className="w-full border rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 outline-none"
                  disabled={filters.filterType === 'monthly'}
                >
                  <option value="month">This Month</option>
                  <option value="3months">Past 3 Months</option>
                  <option value="6months">Past 6 Months</option>
                  <option value="9months">Past 9 Months</option>
                  <option value="year">Past Year</option>
                </select>
              </div>

              {/* Monthly Filter */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Monthly Filter</label>
                <input
                  type="month"
                  value={filters.filterType === 'monthly' ? filters.startDate.toISOString().slice(0, 7) : ''}
                  onChange={(e) => {
                    if (e.target.value) {
                      setFilters(prev => ({ ...prev, filterType: 'monthly' }));
                      handleDateRangeChange(e.target.value, 'monthly');
                    }
                  }}
                  className="w-full border rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 outline-none"
                  disabled={filters.filterType === 'preset'}
                />
              </div>
            </div>
          )}

          {/* Event Type Filter */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Event Type</label>
            <select
              value={filters.eventType}
              onChange={(e) => setFilters(prev => ({ ...prev, eventType: e.target.value }))}
              className="w-full border rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 outline-none"
            >
              <option value="">All Types</option>
              {eventTypes.map((type) => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
          </div>
        </div>

        {/* Custom Date Range */}
        {filters.dateRange === 'custom' && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Start Date</label>
              <input
                type="date"
                value={filters.startDate.toISOString().split('T')[0]}
                onChange={(e) => setFilters(prev => ({ 
                  ...prev, 
                  startDate: new Date(e.target.value) 
                }))}
                className="w-full border rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 outline-none"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">End Date</label>
              <input
                type="date"
                value={filters.endDate.toISOString().split('T')[0]}
                onChange={(e) => setFilters(prev => ({ 
                  ...prev, 
                  endDate: new Date(e.target.value) 
                }))}
                className="w-full border rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 outline-none"
              />
            </div>
          </div>
        )}

        {/* Action Buttons */}
        <div className="flex justify-end space-x-4 mt-6">
          <button
            onClick={handlePrint}
            className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            <FaPrint className="mr-2" />
            Print Report
          </button>
          <button
            onClick={handleExport}
            className="flex items-center px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
          >
            <FaDownload className="mr-2" />
            Export CSV
          </button>
        </div>
      </div>

      {/* Report Content */}
      {loading ? (
        <div className="animate-pulse space-y-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {[...Array(3)].map((_, i) => (
              <div key={i} className="bg-white p-6 rounded-lg shadow-md">
                <div className="h-4 bg-gray-200 rounded w-24 mb-3"></div>
                <div className="h-8 bg-gray-200 rounded w-32"></div>
              </div>
            ))}
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="h-4 bg-gray-200 rounded w-48 mb-4"></div>
            <div className="h-[300px] bg-gray-200 rounded"></div>
          </div>
        </div>
      ) : error ? (
        <div className="bg-red-50 text-red-500 p-4 rounded-lg">
          Error loading report: {error}
        </div>
      ) : reportData && (
        <div className="space-y-8">
          {/* Summary Cards */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-gray-500 text-sm font-semibold mb-1">Total Revenue</h3>
              <p className="text-2xl font-bold">${reportData.totalRevenue.toFixed(2)}</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-gray-500 text-sm font-semibold mb-1">Total Tickets</h3>
              <p className="text-2xl font-bold">{reportData.totalTickets}</p>
            </div>
          </div>

          {/* Charts */}
          {reportData.dailySales.length > 0 && (
            <>
              {/* Revenue Chart */}
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-lg font-semibold mb-4">Revenue Over Time</h3>
                <div className="h-[300px]">
                  <ResponsiveContainer width="100%" height="100%">
                    <AreaChart data={reportData.dailySales}>
                      <defs>
                        <linearGradient id="colorRevenue" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#0088FE" stopOpacity={0.8}/>
                          <stop offset="95%" stopColor="#0088FE" stopOpacity={0}/>
                        </linearGradient>
                      </defs>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip 
                        formatter={(value) => [`$${value}`, 'Revenue']}
                        contentStyle={{ 
                          backgroundColor: 'white',
                          border: '1px solid #ddd',
                          borderRadius: '8px',
                          padding: '10px'
                        }}
                      />
                      <Area 
                        type="monotone" 
                        dataKey="revenue" 
                        stroke="#0088FE" 
                        fillOpacity={1} 
                        fill="url(#colorRevenue)" 
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </div>

              {/* Tickets Chart */}
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-lg font-semibold mb-4">Daily Ticket Sales</h3>
                <div className="h-[300px]">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={reportData.dailySales}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip 
                        formatter={(value) => [`${value} tickets`, 'Sales']}
                        contentStyle={{ 
                          backgroundColor: 'white',
                          border: '1px solid #ddd',
                          borderRadius: '8px',
                          padding: '10px'
                        }}
                      />
                      <Bar dataKey="tickets" fill="#0088FE" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </>
          )}

          {/* Detailed Data Table */}
          <div className="bg-white p-6 rounded-lg shadow-md overflow-x-auto">
            <h3 className="text-lg font-semibold mb-4">Detailed Report</h3>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Revenue</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tickets</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Unique Events</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {reportData.dailySales.map((day, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap">{day.date}</td>
                    <td className="px-6 py-4 whitespace-nowrap">${day.revenue.toFixed(2)}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{day.tickets}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{day.uniqueEvents}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportsTab; 