import React, { useState, useEffect } from 'react';
import { supabase, fetchDashboardStats, fetchVendorEventTypes } from '../../supabaseClient';
import { 
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
  PieChart, Pie, Cell, BarChart, Bar
} from 'recharts';
import { 
  FaTicketAlt, FaUsers, FaDollarSign, FaChartLine, 
  FaExchangeAlt, FaUserFriends, FaCalendarAlt, FaChartBar,
  FaPercentage, FaClock
} from 'react-icons/fa';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

const DashboardTab = () => {
  const [stats, setStats] = useState({
    currentMonth: { revenue: 0, tickets: 0 },
    lastMonth: { revenue: 0, tickets: 0 },
    buyers: { total: 0, recurring: 0, averageTickets: '0.0' },
    revenueData: [],
    dailySales: [],
    topEvents: [],
    demographics: [],
    conversionRate: 0,
    peakSalesTime: 'N/A',
    avgPurchaseTime: 0,
    multipleTicketRate: 0,
    returnCustomerRate: 0
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [selectedEventType, setSelectedEventType] = useState('');
  const [eventTypes, setEventTypes] = useState([]);

  // Format date to YYYY-MM for the select value
  const formatDateForSelect = (date) => {
    return date.toISOString().slice(0, 7); // Gets YYYY-MM format
  };

  // Generate last 12 months for selector
  const last12Months = Array.from({length: 12}, (_, i) => {
    const date = new Date();
    date.setMonth(date.getMonth() - i);
    return date;
  });

  const handleMonthChange = (e) => {
    const [year, month] = e.target.value.split('-');
    const newDate = new Date(year, month - 1); // month is 0-based
    setSelectedMonth(newDate);
  };

  useEffect(() => {
    const loadEventTypes = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) throw new Error('No user found');

        const { data: vendorData } = await supabase
          .from('vendors')
          .select('id')
          .eq('user_id', user.id)
          .single();

        const { data: types } = await fetchVendorEventTypes(vendorData.id);
        setEventTypes(types || []);
      } catch (error) {
        console.error('Error loading event types:', error);
      }
    };

    loadEventTypes();
  }, []);

  useEffect(() => {
    const loadDashboardData = async () => {
      try {
        setLoading(true);
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) throw new Error('No user found');

        const { data: vendorData } = await supabase
          .from('vendors')
          .select('id')
          .eq('user_id', user.id)
          .single();

        const dashboardStats = await fetchDashboardStats(
          vendorData.id, 
          selectedMonth,
          selectedEventType || null
        );
        setStats(dashboardStats);
      } catch (error) {
        console.error('Error loading dashboard:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    loadDashboardData();
  }, [selectedMonth, selectedEventType]);

  // Add a check before rendering sensitive sections
  const renderContent = () => {
    if (!stats) return null;

    // Calculate percentage changes with proper handling of zero/undefined cases
    const calculatePercentageChange = (current, previous) => {
      // If both months have zero sales, return 0 (no change)
      if (current === 0 && previous === 0) {
        return 0;
      }
      
      // If only previous month was zero
      if (previous === 0 || !previous) {
        return current > 0 ? 100 : 0;
      }
      
      return ((current - previous) / previous * 100);
    };

    const revenueChange = calculatePercentageChange(
      stats.currentMonth.revenue,
      stats.lastMonth.revenue
    );

    const ticketsChange = calculatePercentageChange(
      stats.currentMonth.tickets,
      stats.lastMonth.tickets
    );

    return (
      <div className="p-6 space-y-8">
        {/* Month Selector - Always show this even during loading */}
        <div className="bg-white p-4 rounded-lg shadow-md">
          <div className="flex items-center justify-between gap-4">
            <h2 className="text-xl font-semibold flex items-center">
              <FaCalendarAlt className="mr-2 text-blue-600" />
              Dashboard Overview
            </h2>
            <div className="flex gap-4">
              <select
                value={selectedEventType}
                onChange={(e) => setSelectedEventType(e.target.value)}
                className="border rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 outline-none"
                disabled={loading}
              >
                <option value="">All Event Types</option>
                {eventTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>

              <select
                value={formatDateForSelect(selectedMonth)}
                onChange={handleMonthChange}
                className="border rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 outline-none"
                disabled={loading}
              >
                {last12Months.map((date) => (
                  <option 
                    key={formatDateForSelect(date)} 
                    value={formatDateForSelect(date)}
                  >
                    {date.toLocaleString('default', { month: 'long', year: 'numeric' })}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* Stats Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {/* Monthly Revenue Card */}
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-gray-500 text-sm font-semibold mb-1">Monthly Revenue</p>
                <h3 className="text-2xl font-bold">${stats.currentMonth.revenue.toFixed(2)}</h3>
                <div className={`flex items-center text-sm ${revenueChange >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                  <FaChartLine className="mr-1" />
                  <span>{revenueChange.toFixed(1)}% vs last month</span>
                </div>
              </div>
              <div className="p-3 bg-blue-100 rounded-full">
                <FaDollarSign className="text-blue-600 text-xl" />
              </div>
            </div>
          </div>

          {/* Monthly Tickets Card */}
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-gray-500 text-sm font-semibold mb-1">Tickets Sold (Month)</p>
                <h3 className="text-2xl font-bold">{stats.currentMonth.tickets}</h3>
                <div className={`flex items-center text-sm ${ticketsChange >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                  <FaChartLine className="mr-1" />
                  <span>{ticketsChange.toFixed(1)}% vs last month</span>
                </div>
              </div>
              <div className="p-3 bg-purple-100 rounded-full">
                <FaTicketAlt className="text-purple-600 text-xl" />
              </div>
            </div>
          </div>

          {/* Recurring Buyers Card */}
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-gray-500 text-sm font-semibold mb-1">Recurring Buyers</p>
                <h3 className="text-2xl font-bold">{stats.buyers.recurring}</h3>
                <div className="flex items-center text-sm text-gray-500">
                  <FaUserFriends className="mr-1" />
                  <span>Out of {stats.buyers.total} total buyers</span>
                </div>
              </div>
              <div className="p-3 bg-green-100 rounded-full">
                <FaExchangeAlt className="text-green-600 text-xl" />
              </div>
            </div>
          </div>

          {/* Average Tickets Per Buyer */}
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-gray-500 text-sm font-semibold mb-1">Avg. Tickets/Buyer</p>
                <h3 className="text-2xl font-bold">{stats.buyers.averageTickets}</h3>
                <div className="flex items-center text-sm text-gray-500">
                  <FaUsers className="mr-1" />
                  <span>Per customer</span>
                </div>
              </div>
              <div className="p-3 bg-yellow-100 rounded-full">
                <FaUsers className="text-yellow-600 text-xl" />
              </div>
            </div>
          </div>

          {/* Conversion Rate Card */}
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-gray-500 text-sm font-semibold mb-1">Conversion Rate</p>
                <h3 className="text-2xl font-bold">{stats.conversionRate}%</h3>
                <div className="flex items-center text-sm text-gray-500">
                  <FaPercentage className="mr-1" />
                  <span>Of page views</span>
                </div>
              </div>
              <div className="p-3 bg-indigo-100 rounded-full">
                <FaChartBar className="text-indigo-600 text-xl" />
              </div>
            </div>
          </div>

          {/* Peak Sales Time Card */}
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-gray-500 text-sm font-semibold mb-1">Peak Sales Time</p>
                <h3 className="text-2xl font-bold">{stats.peakSalesTime}</h3>
                <div className="flex items-center text-sm text-gray-500">
                  <FaClock className="mr-1" />
                  <span>Most active hour</span>
                </div>
              </div>
              <div className="p-3 bg-red-100 rounded-full">
                <FaClock className="text-red-600 text-xl" />
              </div>
            </div>
          </div>

          {/* Best Performing Sport Card */}
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-gray-500 text-sm font-semibold mb-1">Best Performing Sport</p>
                <h3 className="text-2xl font-bold">{stats.bestSport.name}</h3>
                <div className="flex items-center text-sm text-gray-500">
                  <FaTicketAlt className="mr-1" />
                  <span>{stats.bestSport.tickets} tickets (${stats.bestSport.revenue.toFixed(2)})</span>
                </div>
              </div>
              <div className="p-3 bg-orange-100 rounded-full">
                <FaChartLine className="text-orange-600 text-xl" />
              </div>
            </div>
          </div>
        </div>

        {/* Charts */}
        {stats.dailySales.length > 0 && (
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold mb-4">Daily Sales Performance</h3>
            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={stats.dailySales}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip 
                    formatter={(value) => [`${value} tickets`, 'Sales']}
                    contentStyle={{ 
                      backgroundColor: 'white',
                      border: '1px solid #ddd',
                      borderRadius: '8px',
                      padding: '10px'
                    }}
                  />
                  <Bar dataKey="sales" fill="#0088FE" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        )}

        {stats.revenueData.length > 0 && (
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold mb-4">Revenue Trend</h3>
            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={stats.revenueData}>
                  <defs>
                    <linearGradient id="colorRevenue" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#0088FE" stopOpacity={0.8}/>
                      <stop offset="95%" stopColor="#0088FE" stopOpacity={0}/>
                    </linearGradient>
                  </defs>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip 
                    formatter={(value) => [`$${value}`, 'Revenue']}
                    labelStyle={{ color: '#666' }}
                    contentStyle={{ 
                      backgroundColor: 'white',
                      border: '1px solid #ddd',
                      borderRadius: '8px',
                      padding: '10px'
                    }}
                  />
                  <Area 
                    type="monotone" 
                    dataKey="amount" 
                    stroke="#0088FE" 
                    fillOpacity={1} 
                    fill="url(#colorRevenue)" 
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
        )}

        {/* Top Events */}
        {stats.topEvents.length > 0 && (
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold mb-4">Top Performing Events</h3>
            <div className="space-y-4">
              {stats.topEvents.map((event, index) => (
                <div key={index} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                  <div className="flex items-center space-x-4">
                    <span className="text-lg font-bold text-gray-500">#{index + 1}</span>
                    <div>
                      <h4 className="font-semibold">{event.title}</h4>
                      <p className="text-sm text-gray-500">{event.date}</p>
                    </div>
                  </div>
                  <div className="text-right">
                    <p className="font-bold">${event.revenue}</p>
                    <p className="text-sm text-gray-500">{event.tickets} tickets</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Customer Insights */}
        {stats.demographics.length > 0 && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-lg font-semibold mb-4">Customer Demographics</h3>
              <div className="h-[300px]">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={stats.demographics}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {stats.demographics.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-lg font-semibold mb-4">Purchase Patterns</h3>
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <span>Average Purchase Time</span>
                  <span className="font-semibold">{stats.avgPurchaseTime} days before event</span>
                </div>
                <div className="flex justify-between items-center">
                  <span>Multiple Ticket Purchases</span>
                  <span className="font-semibold">{stats.multipleTicketRate}%</span>
                </div>
                <div className="flex justify-between items-center">
                  <span>Return Customer Rate</span>
                  <span className="font-semibold">{stats.returnCustomerRate}%</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  // Show skeleton loader for both initial load and month changes
  if (loading) {
    return (
      <div className="p-6">
        {/* Keep month selector visible during loading */}
        <div className="bg-white p-4 rounded-lg shadow-md mb-8">
          <div className="flex items-center justify-between gap-4">
            <h2 className="text-xl font-semibold flex items-center">
              <FaCalendarAlt className="mr-2 text-blue-600" />
              Dashboard Overview
            </h2>
            <div className="flex gap-4">
              <select
                value={selectedEventType}
                onChange={(e) => setSelectedEventType(e.target.value)}
                className="border rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 outline-none"
                disabled
              >
                <option value="">All Event Types</option>
                {eventTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>

              <select
                value={formatDateForSelect(selectedMonth)}
                onChange={handleMonthChange}
                className="border rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 outline-none"
                disabled
              >
                {last12Months.map((date) => (
                  <option 
                    key={formatDateForSelect(date)} 
                    value={formatDateForSelect(date)}
                  >
                    {date.toLocaleString('default', { month: 'long', year: 'numeric' })}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="animate-pulse space-y-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {[...Array(6)].map((_, i) => (
              <div key={i} className="bg-white p-6 rounded-lg shadow-md">
                <div className="h-4 bg-gray-200 rounded w-24 mb-3"></div>
                <div className="h-8 bg-gray-200 rounded w-32 mb-2"></div>
                <div className="h-4 bg-gray-200 rounded w-20"></div>
              </div>
            ))}
          </div>
          {/* Charts skeletons */}
          {[...Array(3)].map((_, i) => (
            <div key={i} className="bg-white p-6 rounded-lg shadow-md">
              <div className="h-4 bg-gray-200 rounded w-48 mb-4"></div>
              <div className="h-[300px] bg-gray-200 rounded"></div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6">
        <div className="bg-red-50 text-red-500 p-4 rounded-lg">
          Error loading dashboard: {error}
        </div>
      </div>
    );
  }

  return renderContent();
};

export default DashboardTab;
